<template>
  <section id="new-connection">
    <b-row class="match-height">
      <b-col cols="12">
        <b-card no-body>
          <b-card-header>
            <b-card-title>
              Подключение Google Adwords
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-col cols="4">
              <b-overlay
                :show="showMainCustomersLoading"
                blur="0"
                opacity=".75"
                rounded="sm"
                spinner-variant="primary"
                variant="white"
              >
                <h6>Выберите управляющий аккаунт</h6>
                <v-select
                  id="account-select"
                  v-model="account"
                  :options="accountOptions"
                  item-text="name"
                  item-value="id"
                  label="name"
                  class="mb-1 mt-1"
                  placeholder="Выберите необходимый аккаунт..."
                >
                  <template v-slot:no-options>
                    Ничего не найдено
                  </template>
                </v-select>
              </b-overlay>
            </b-col>
            <b-col
              class="mt-15"
              cols="4"
            >
              <div class="mt-15">
                <b-overlay
                  :show="showClientCustomersLoading"
                  blur="0"
                  opacity=".75"
                  rounded="sm"
                  spinner-variant="primary"
                  variant="white"
                >
                  <h6>Выберите аккаунт клиента</h6>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    size="sm"
                    @click="getClients"
                  >
                    Получить аккаунты
                  </b-button>
                  <v-select
                    id="account-client-select"
                    v-model="client"
                    :options="clientOptions"
                    item-text="name"
                    item-value="id"
                    label="name"
                    class="mb-1 mt-1"
                    placeholder="Выберите необходимый аккаунт..."
                  >
                    <template v-slot:no-options>
                      Ничего не найдено
                    </template>
                  </v-select>
                </b-overlay>
              </div>
            </b-col>
            <b-col cols="4">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="mt-1"
                variant="outline-primary"
                @click="saveIntegration()"
              >
                Сохранить выбор
              </b-button>
            </b-col>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BOverlay,
  BSpinner,
  BCardTitle,
  BFormCheckbox,
  BCol,
  BRow,
  VBToggle, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import OpenIndicator from '@core/components/vue-select/OpenIndicator.vue'

vSelect.props.components.default = () => ({ OpenIndicator })

export default {
  components: {
    BCard,
    BButton,
    vSelect,
    BCardHeader,
    BCardBody,
    BOverlay,
    BSpinner,
    BFormCheckbox,
    BCardTitle,
    BRow,
    BCol,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    connectionId: [String, Number],
  },
  data() {
    return {
      checkboxPosition: 'left',
      showRedirect: false,
      account: null,
      client: null,
      webProperty: null,
      showMainCustomersLoading: false,
      showClientCustomersLoading: false,
      profile: null,
      webPropertyOptions: [],
      checkedGoals: [],
      profileOptions: [],
      accountOptions: [],
      clientOptions: [],
      goalsData: [],
      googleTokens: [],
      isEcommerce: false,
    }
  },
  computed: {},
  mounted() {
    this.$gapi.getGapiClient()
      .then(gapi => {
        gapi.auth2.getAuthInstance()
          .grantOfflineAccess()
          .then(res => {
            this.$http.get(`api/googleads/refresh?code=${res.code}`)
            // eslint-disable-next-line no-unused-vars
              .then(response => {
                this.googleTokens = response.data
                this.showMainCustomersLoading = true
                this.$http.get(`api/googleads/customers?accessToken=${this.googleTokens.accessToken}`)
                // eslint-disable-next-line no-unused-vars
                  .then(resa => {
                    resa.data.forEach(element => this.accountOptions.push({
                      id: element.id, // Примитивное значение для v-model
                      name: `${element.name} (${element.id})`,
                    }))
                    this.showMainCustomersLoading = false
                  })
              })
          })
      })
  },
  created() {
  },
  methods: {
    customerName(item) {
      return `${item.name} ( ${item.id} )`
    },
    getClients() {
      this.showClientCustomersLoading = true
      this.$http.get(`api/googleads/hierarchy?accessToken=${this.googleTokens.accessToken}&managerCustomerId=${this.account.id}`)
      // eslint-disable-next-line no-unused-vars
        .then(resa => {
          resa.data.forEach(element => this.clientOptions.push({
            id: element.id, // Примитивное значение для v-model
            name: `${element.name} (${element.id})`,
          }))
          this.showClientCustomersLoading = false
        })
    },
    saveIntegration() {
      const connId = this.$route.params.connectionId

      // TO-DO: NEED refactoring
      const obj = {
        refreshToken: this.googleTokens.refreshToken,
        accessToken: this.googleTokens.accessToken,
        accessTokenExpirationDate: this.googleTokens.accessTokenExpirationDate,
        connectionId: connId,
        type: 'GOOGLE_ADWORDS',
        info: JSON.stringify({
          customerId: this.client.id,
        }),
      }
      //  this.saveLoading = true
      this.$http.post('integration/fill', obj)
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$router.push({
            name: 'new-connection',
            params: { connectionId: connId },
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.b-table::v-deep .pagination-list {
  margin-top: 20px !important;
}
</style>
